<header class="header">
  <div class="nav-items" *ngIf="navItems">
    <div
      class="item"
      *ngFor="let item of navItems; index as index"
      (click)="!item.children ? navigate($event, item) : null"
    >
      <p>
        {{ item.name }}
        <img *ngIf="item.children" src="../../../../assets/svg/expand_down_light.svg" />
      </p>

      <div class="sub-nav" *ngIf="item.children">
        <span *ngFor="let child of item.children" (click)="navigate($event, child)">
          {{ child.name }}
        </span>
      </div>
    </div>
  </div>
</header>

<!-- Create / Edit User Modal -->
<app-generic-modal *ngIf="showEditUserModal" (closeModalEmitter)="onCloseEditUserModal()">
  <div title>User Settings</div>
  <div content>
    <form [formGroup]="editUserForm">
      <!-- First Name -->
      <div class="form-row mb-3">
        <div class="col-2">
          <label for="firstName" class="lh-2-5">First Name:</label>
        </div>
        <div class="col">
          <input type="text" class="form-control" formControlName="firstName" />
        </div>
      </div>

      <!-- Last Name -->
      <div class="form-row mb-3">
        <div class="col-2">
          <label for="lastName" class="lh-2-5">Last Name:</label>
        </div>
        <div class="col">
          <input type="text" class="form-control" formControlName="lastName" />
        </div>
      </div>

      <!-- Email -->
      <div class="form-row mb-3">
        <div class="col-2">
          <label for="email" class="lh-2-5">Email:</label>
        </div>
        <div class="col">
          <input type="text" class="form-control" formControlName="email" />
        </div>
      </div>
    </form>

    <!-- Region -->
    <div class="form-row mb-3" *ngIf="currentUser.user_type.userTypeTag === 'ADMIN'">
      <div class="col-2">
        <label for="email" class="lh-2-5">Region:</label>
      </div>
      <div class="col">
        <app-multi-select-checkboxes
          id="region"
          class="w-100"
          [items]="regionCheckBoxes"
          [valuesSelected]="regionsSelected"
          [noneSelectedString]="'Select one or more regions...'"
          (valuesSelectedEmitter)="regionsSelected = $event"
        >
        </app-multi-select-checkboxes>
      </div>
      <!-- Error Message-->
      <div class="col">
        <span *ngIf="!regionValid && regionsSelected.length > 0" class="red">
          Please select a region
        </span>
      </div>
    </div>

    <form [formGroup]="editUserPasswordForm">
      <!-- Old Password -->
      <div class="form-row mb-3">
        <div class="col-2">
          <label for="oldPassword" class="lh-2-5">Current Password *:</label>
        </div>
        <div class="col">
          <input type="password" class="form-control" formControlName="oldPassword" />
        </div>
      </div>

      <!-- New Password -->
      <div class="form-row mb-3">
        <div class="col-2">
          <label for="newPassword" class="lh-2-5">New Password *:</label>
        </div>
        <div class="col">
          <input type="password" class="form-control" formControlName="password" />
        </div>
      </div>

      <!-- New Password Confirmation -->
      <div class="form-row">
        <div class="col-2">
          <label for="passwordConfirm" class="lh-2-5">Confirm Password *:</label>
        </div>
        <div class="col">
          <input type="password" class="form-control" formControlName="passwordConfirm" />
        </div>
      </div>

      <span>
        <small> * These fields are only required if you wish to change your password </small>
      </span>

      <div class="row my-2">
        <div class="col text-center red">
          {{ passwordFormMessage }}
        </div>
      </div>
    </form>

    <!-- Error message -->
    <div class="row mt-2">
      <div class="col text-center">
        <span *ngIf="!editFormValid || !editEmailValid" class="red">
          {{ !editEmailValid ? "Please enter a valid email address" : "All fields are required" }}
        </span>
      </div>
    </div>
  </div>
  <div buttons>
    <button
      type="button"
      class="btn ag-blue left"
      (click)="onCloseEditUserModal()"
      [disabled]="loading"
    >
      <span>Cancel</span>
    </button>
    <button
      type="button"
      class="btn ag-blue"
      (click)="editUser()"
      [disabled]="loading || !regionsSelected.length"
    >
      <span>Save</span>
    </button>
  </div>
</app-generic-modal>
