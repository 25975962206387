import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subject-company-competency',
  templateUrl: './subject-company-competency.component.html',
  styleUrls: ['./subject-company-competency.component.scss'],
})
export default class SubjectCompanyCompetencyComponent implements OnInit {
  public constructor() {}

  public ngOnInit(): void {}
}
