import { Component, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "app-generic-modal",
  templateUrl: "./generic-modal.component.html",
  styleUrls: ["./generic-modal.component.scss"],
})
export class GenericModalComponent {
  @Input() width = "30%";

  @Output() closeModalEmitter = new EventEmitter();

  public closeModal(): void {
    this.closeModalEmitter.emit();
  }
}
