import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { APIResponse } from '@shared/definitions';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
// Services
import LoginService from '@services/user/login.service';
import AlertService from '@services/alert.service';
import UserService from '@services/user/user.service';
import ForgottenPasswordService from '@services/user/forgotten-password.service';
// Interfaces
import { ILoginDetails } from '@interfaces/user/login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public forgotPasswordClicked = false;
  public loginForm: UntypedFormGroup;
  public loading = false;
  public emailValid = true;
  public passwordValid = true;

  constructor(
    private loginSerivce: LoginService,
    private forgottenPasswordService: ForgottenPasswordService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  /**
   * Starts the login process by sending form details to LoginService
   *
   * @return void
   */
  private login(): void {
    this.loading = true;
    const details: ILoginDetails = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    };
    this.loginSerivce.login(details).subscribe(
      (response: APIResponse) => {
        localStorage.setItem('JWT', response.data.token);
        this.userService.setCurrentUserType(response.data.userType);
        this.userService.setCurrentUser(response.data.user);
        this.router.navigateByUrl('dashboard');
        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        this.alertService.emitErrorAlert(error.error.message || 'Invalid login details');
      },
    );
  }

  /**
   * Starts the password reset process by sending form details to ForgottenPasswordService
   *
   * @return void
   */
  public forgotPassword(): void {
    this.loading = true;
    const email = this.loginForm.value.email;
    this.emailValid = this.loginForm.controls.email.valid;
    if (this.emailValid) {
      this.forgottenPasswordService.sendPasswordResetEmail(email).subscribe({
        next: () => {
          this.alertService.emitSuccessAlert('Reset email sent');
          this.loading = false;
          this.forgotPasswordClicked = false;
        },
        error: (error: HttpErrorResponse) => {
          this.alertService.emitErrorAlert(error.error.message || 'Reset email failed');
          this.loading = false;
        },
      });
    }
  }

  /**
   * Checks the validity of the login form
   *
   * @return void
   */
  public onLoginClick(): void {
    this.emailValid = this.loginForm.controls.email.valid;
    this.passwordValid = this.loginForm.controls.password.valid;

    if (this.emailValid && this.passwordValid) {
      this.login();
    }
  }
}
