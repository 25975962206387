import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
// Services
import HttpParamsService from '@services/http-params.service';
// Interface
import { APIResponse, User } from '@shared/definitions';
import {
  ICreateUserParams,
  IDeleteUserParams,
  IEditUserParams,
  IUserActiveStatus,
  IUserSearchParams,
} from '@interfaces/user/user';

@Injectable({
  providedIn: 'root',
})
export default class UserService {
  private currentUserTypeEmitter = new BehaviorSubject<string>(null);
  private currentUserEmitter = new BehaviorSubject<User>(null);
  public currentUserType = this.currentUserTypeEmitter.asObservable();
  public currentUser = this.currentUserEmitter.asObservable();

  constructor(
    private http: HttpClient,
    private httpParamsService: HttpParamsService,
  ) {}

  /**
   * Calls the API to get all users
   *
   * @param IUserSearchParams params
   *
   * @return Observable<APIResponse>
   */
  public list(params: IUserSearchParams): Observable<APIResponse> {
    const url = `${environment.apiURL}/api/user/list`;
    const httpParams = this.httpParamsService.getHttpParams(params);
    return this.http.get<APIResponse>(url, { params: httpParams });
  }

  /**
   * Sends a request to the API to get the current user details
   *
   * @return Observable<APIResponse>
   */
  public getCurrent(): Observable<APIResponse> {
    const url = `${environment.apiURL}/api/user/current`;
    return this.http.get<APIResponse>(url);
  }

  /**
   * Emits a new value from the currentUserTypeEmitter behaviour subject
   *
   * @param string newType
   *
   * @return void
   */
  public setCurrentUserType(newType: string): void {
    this.currentUserTypeEmitter.next(newType);
  }

  /**
   * Emits a new value from the currentUserEmitter behaviour subject
   *
   * @param User newUser
   *
   * @return void
   */
  public setCurrentUser(newUser: User): void {
    this.currentUserEmitter.next(newUser);
  }

  /**
   * Gets all possible user types
   *
   * @return void
   */
  public getUserTypes(): Observable<APIResponse> {
    const url = `${environment.apiURL}/api/user/types`;
    return this.http.get<APIResponse>(url);
  }

  /**
   * Calls the API to create a new user
   *
   * @param ICreateUserParams params
   *
   * @return Observable<APIResponse>
   */
  public createUser(params: ICreateUserParams): Observable<APIResponse> {
    const url = `${environment.apiURL}/api/user/create`;
    return this.http.post<APIResponse>(url, params);
  }

  /**
   * Calls the API to edit an existing user
   *
   * @param IEditUserParams params
   * @param number userId
   *
   * @return Observable<APIResponse>
   */
  public editUser(params: IEditUserParams, userId: number): Observable<APIResponse> {
    const url = `${environment.apiURL}/api/user/${userId}/edit`;
    return this.http.put<APIResponse>(url, params);
  }

  public deleteUser(deleteParams: IDeleteUserParams): Observable<APIResponse> {
    const url = `${environment.apiURL}/api/user/delete`;
    return this.http.post<APIResponse>(url, deleteParams);
  }

  /**
   * Gets all possible user statuses
   *
   * @return string
   */
  public getUserStatuses(): IUserActiveStatus[] {
    return [
      { name: 'Active', value: 1 },
      { name: 'Inactive', value: 0 },
    ];
  }
}
