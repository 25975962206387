import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private _navItems: INavItems[] = [
    { name: 'Home', route: '/dashboard/programs' },
    {
      name: 'Coverage',
      route: 'coverage',
      children: [
        { name: 'Survey Overview', route: '/coverage/survey-overview' },
        { name: 'Program & Product', route: '/coverage/program-and-product' },
        { name: 'Respondent Company', route: '/coverage/respondent-company' },
        {
          name: 'Subject Company & Competency',
          route: '/coverage/subject-company-and-competency',
        },
      ],
    },
    {
      name: 'Respondent Management',
      route: 'respondent-management',
      children: [
        // { name: 'Suppliers', route: '/suppliers' },
        // { name: 'Retailers', route: '/retailers' },
        // { name: 'Respondents', route: '/respondents' },
        // { name: 'Tracking', route: '/tracking' },
        // { name: 'Interview Tracker', route: '/interview-tracker' },
        { name: 'UK Management', route: '/dashboard/programs' },
        { name: 'Survey Response Tracker', route: '/survey-response-tracker' },
      ],
    },
    {
      name: 'Date Management',
      route: '/data-management',
      children: [
        { name: 'Imports', route: '/data-management/imports' },
        { name: 'History', route: '/data-management/history' },
      ],
    },
    { name: 'User Management', route: '/dashboard/user-management' },
    {
      name: 'User',
      route: '/user',
      children: [
        { name: 'User Settings', route: '/user-settings', userSettings: true },
        { name: 'Logout', route: '/logout', logout: true },
      ],
    },
  ];

  public get getNavItems(): INavItems[] {
    return this._navItems;
  }
}

export interface INavItems {
  name: string;
  route: string;
  children?: INavItems[];
  logout?: boolean;
  userSettings?: boolean;
}
