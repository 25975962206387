import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from 'src/app/guards/LoginGuard';
import HistoryComponent from './history/history.component';
import ImportsComponent from './imports/imports.component';

const dataManagementRoutes: Routes = [
  {
    path: 'data-management/history',
    component: HistoryComponent,
  },
  {
    path: 'data-management/imports',
    component: ImportsComponent,
  },
];

@NgModule({
  declarations: [HistoryComponent, ImportsComponent],
  imports: [RouterModule.forChild(dataManagementRoutes)],
  providers: [],
  exports: [RouterModule],
})
export class DataManagementModule {}
