import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
// Services
import AuthService from '../auth.service';

@Injectable()
export default class TokenInterceptorService implements HttpInterceptor {
  /*eslint-disable  @typescript-eslint/no-explicit-any */
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    const tokenValid = this.authService.isAuthenticated();

    if (tokenValid) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else if (!request.url.includes('login')) {
      localStorage.removeItem('JWT');
      this.router.navigate(['login']);
    }

    return next.handle(request);
  }
}
