import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-survey-overview',
  templateUrl: './survey-overview.component.html',
  styleUrls: ['./survey-overview.component.scss'],
})
export default class SurveyOverviewComponent implements OnInit {
  public constructor() {}

  public ngOnInit(): void {}
}
