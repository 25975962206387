import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-respondent-company',
  templateUrl: './respondent-company.component.html',
  styleUrls: ['./respondent-company.component.scss'],
})
export default class RespondentCompanyComponent implements OnInit {
  public constructor() {}

  public ngOnInit(): void {}
}
