<div
  class="container"
  [ngStyle]="{
    width: width
  }"
  (clickOutside)="closeModal()"
  [clickOutsideEvents]="'mousedown'"
  [clickOutsideExclude]="'.exclude-from-click-outside'"
>
  <div class="row">
    <div class="col">
      <h3>
        <ng-content select="[title]"></ng-content>
      </h3>
    </div>
    <div class="col">
      <button type="button" class="close" (click)="closeModal()">
        <span>&times;</span>
      </button>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col">
      <ng-content select="[content]"></ng-content>
    </div>
  </div>
  <hr />
  <div class="row right">
    <div class="col">
      <ng-content select="[buttons]"></ng-content>
    </div>
  </div>
</div>

<div class="page-mask"></div>
