import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CheckBoxItem } from '@shared/definitions';

@Component({
  selector: 'app-multi-select-checkboxes',
  templateUrl: './multi-select-checkboxes.component.html',
  styleUrls: ['./multi-select-checkboxes.component.scss'],
})
export class MultiSelectCheckboxesComponent implements OnInit, OnChanges {
  @Input() items: CheckBoxItem[] = [];
  /*eslint-disable @typescript-eslint/no-explicit-any */
  @Input() valuesSelected: any[] = [];
  @Input() noneSelectedString: string;
  @Input() disabled = false;
  @Input() allowAll = true;
  @Input() filterable = false;

  @Output() valuesSelectedEmitter = new EventEmitter<any[]>();
  /*eslint-enable @typescript-eslint/no-explicit-any */

  public display = false;

  public filteredItems = this.items;
  public filterText = '';

  ngOnInit() {
    this.filterItems();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this.filterItems();
    }
  }
  /**
   * Emits the item checked when it is clicked
   *
   * @param CheckBoxItem item
   *
   * @return void
   */
  public onCheckBoxClick(item: CheckBoxItem): void {
    // If item supplied, otherwise ALL ticked
    if (item) {
      item.checked = !item.checked;

      if (this.allowAll) {
        if (item.checked) {
          this.valuesSelected.push(item.value);
        } else {
          this.valuesSelected.splice(this.valuesSelected.indexOf(item.value), 1);
        }
      } else {
        this.items.map((checkBoxItem: CheckBoxItem) => {
          return checkBoxItem.value === item.value
            ? (checkBoxItem.checked = true)
            : (checkBoxItem.checked = false);
        });
        this.valuesSelected = [];
        if (item.checked) {
          this.valuesSelected.push(item.value);
        }
      }
    } else {
      // Check if we should be selecting or deselecting all
      if (this.valuesSelected.length < this.items.length) {
        this.items.forEach((i: CheckBoxItem) => {
          if (!i.checked) {
            i.checked = true;
            this.valuesSelected.push(i.value);
          }
        });
      } else {
        // Get rid of all items not disabled (disabled items should not be able to be modified)
        this.items.forEach((i: CheckBoxItem) => {
          if (!i.disabled) {
            this.valuesSelected.splice(this.valuesSelected.indexOf(i.value), 1);
            i.checked = false;
          }
        });
      }
    }

    this.valuesSelectedEmitter.emit(this.valuesSelected);
  }

  /**
   * Works out and returns the string to display depending on which checkboxes are selected
   *
   * @return string
   */
  public get selectedString(): string {
    switch (this.valuesSelected.length) {
      case 0:
        return this.noneSelectedString;
      case 1: {
        const index = this.items.findIndex((item: CheckBoxItem) => {
          return item.value === this.valuesSelected[0];
        });
        return this.items[index].name;
      }
      case this.items.length:
        return 'All';
      default:
        return 'Multiple';
    }
  }

  filterItems() {
    this.filteredItems = this.items.filter((item) =>
      item.name.toLowerCase().includes(this.filterText.toLowerCase()),
    );
  }

  isFiltered(): boolean {
    return this.filterText.length > 0;
  }
}
