import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from 'src/app/guards/LoginGuard';
import ProgramAndProductComponent from './program-and-product/program-and-product.component';
import RespondentCompanyComponent from './respondent-company/respondent-company.component';
import SubjectCompanyCompetencyComponent from './subject-company-competency/subject-company-competency.component';
import SurveyOverviewComponent from './survey-overview/survey-overview.component';

const coverageRoutes: Routes = [
  {
    path: 'program-and-product',
    component: ProgramAndProductComponent,
  },
  {
    path: 'respondent-company',
    component: RespondentCompanyComponent,
  },
  {
    path: 'subject-company-and-competency',
    component: SubjectCompanyCompetencyComponent,
  },
  {
    path: 'survey-overview',
    component: SurveyOverviewComponent,
  },
];

@NgModule({
  declarations: [
    ProgramAndProductComponent,
    RespondentCompanyComponent,
    SubjectCompanyCompetencyComponent,
    SurveyOverviewComponent,
  ],
  imports: [RouterModule.forChild(coverageRoutes)],
  providers: [],
  exports: [RouterModule],
})
export class CoverageModule {}
