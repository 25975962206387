import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export default class AlertService {
  private alertEmitter = new BehaviorSubject<Alert>(new Alert(2, ''));
  public alert = this.alertEmitter.asObservable();
  public timer = null;

  /**
   * Creates a new success alert and begins the emit process
   *
   * @param string message - content of alert
   *
   * @return void
   */
  public emitSuccessAlert(message: string): void {
    const alert = new Alert(1, message);
    this.emitAlert(alert);
  }

  /**
   * Creates a new error alert and begins the emit process
   *
   * @param string message - content of alert
   *
   * @return void
   */
  public emitErrorAlert(message: string): void {
    const alert = new Alert(2, message);
    this.emitAlert(alert);
  }

  /**
   * Emits a new alert using a behaviour subject
   *
   * @param Alert alert
   *
   * @return void
   */
  private emitAlert(alert: Alert): void {
    alert.display = true;
    this.alertEmitter.next(alert);

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      alert.display = false;
      this.alertEmitter.next(alert);
    }, 5000);
  }
}

/**
 * Stores information about the alert
 */
export class Alert {
  public message: string;
  public type: number;
  public display = false;

  constructor(type: number, message: string) {
    this.message = message;
    this.type = type;
  }
}
