import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
// Interfaces
import { APIResponse } from '@shared/definitions';
import { IForgottenPasswordParams } from '@interfaces/user/forgotten-password';

@Injectable({
  providedIn: 'root',
})
export default class ForgottenPasswordService {
  constructor(
    private http: HttpClient,
    public handler: HttpBackend,
  ) {
    this.http = new HttpClient(handler);
  }

  /**
   * Sends email address to the backend so reset email can be sent
   *
   * @param string email
   *
   * @return Observable<APIResponse>
   */
  public sendPasswordResetEmail(email: string): Observable<APIResponse> {
    const url = `${environment.apiURL}/api/user/reset-password-email`;
    return this.http.post<APIResponse>(url, { email });
  }

  /**
   * Sends a request to the API to reset a user's password
   *
   * @param params IForgottenPasswordParams params
   *
   * @return Observable<APIResponse>
   */
  public resetPassword(params: IForgottenPasswordParams): Observable<APIResponse> {
    const url = `${environment.apiURL}/api/user/reset-password`;
    return this.http.put<APIResponse>(url, { params });
  }
}
