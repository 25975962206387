import { Router } from '@angular/router';
import AuthService from '@services/auth.service';
import { Injectable } from '@angular/core';

@Injectable()
export class LoginGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  /**
   * Checks the user can activate this route
   *
   * @param next - next guard (if any)
   * @param state - data passed into route (if any)
   *
   * @return boolean
   */
  canActivate(): boolean {
    const token = this.authService.getToken();

    if (token) {
      return true;
    }

    this.router.navigate(['login']);
    return false;
  }
}
