import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export default class HistoryComponent implements OnInit {
  public constructor() {}

  public ngOnInit(): void {}
}
