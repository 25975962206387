import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-imports',
  templateUrl: './imports.component.html',
  styleUrls: ['./imports.component.scss'],
})
export default class ImportsComponent implements OnInit {
  public constructor() {}

  public ngOnInit(): void {}
}
