<div
  class="alert"
  role="alert"
  [ngClass]="{
    'alert-success': alert.type === 1,
    'alert-danger': alert.type === 2,
    login: loginScreen,
    dashboard: !loginScreen,
    out: !alert.display,
    in: alert.display
  }"
>
  <strong>{{ alert.type === 1 ? "Success! " : "Error! " }}</strong>
  {{ alert.message }}
</div>
