import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export default class MaintenanceInterceptorService implements HttpInterceptor {
  constructor(private router: Router) {}
  /*eslint-disable  @typescript-eslint/no-explicit-any */
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(httpRequest).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 503) {
              this.router.navigateByUrl('maintenance-page');
            }
          }
        },
      }),
    );
  }
}
