import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { APIResponse } from '@shared/definitions';
import HttpParamsService from '@services/http-params.service';
import {
  IDeleteProgramParams,
  IProgramCreateParams,
  IProgramEditParams,
  IProgramSearchParams,
  ISavePrioritiesParams,
} from '@interfaces/program/program';

@Injectable({
  providedIn: 'root',
})
export default class ProgramService {
  constructor(
    private http: HttpClient,
    private httpParamsService: HttpParamsService,
  ) {}

  public refreshProgramList: Subject<boolean> = new Subject<boolean>();

  public setRefreshProgramList(val: boolean) {
    this.refreshProgramList.next(val);
  }

  public getRefreshProgramList(): Observable<boolean> {
    return this.refreshProgramList.asObservable();
  }

  /**
   * Calls the API to retreive all Programs matching search criteria
   *
   * @param IProgramSearchParams searchParams
   *
   * @return Observable<APIResponse>
   */
  public getPrograms(searchParams: IProgramSearchParams): Observable<APIResponse> {
    const url = `${environment.apiURL}/api/programs/list`;

    const params = this.httpParamsService.getHttpParams(searchParams);

    return this.http.get<APIResponse>(url, { params });
  }

  /**
   * Calls the API to save new priorities for a programme
   * @param ISavePrioritiesParams params
   *
   * @returns Observable<APIResponse>
   */
  public savePriorities(params: ISavePrioritiesParams) {
    const url = `${environment.apiURL}/api/programs/save-priority-list`;
    return this.http.post<APIResponse>(url, params);
  }

  /**
   * Calls the API to retreive a specific Program
   *
   * @param number programId
   *
   * @return Observable<APIResponse>
   */
  public getProgramById(programId: number): Observable<APIResponse> {
    const url = `${environment.apiURL}/api/programs/${programId}`;
    return this.http.get<APIResponse>(url);
  }

  /**
   * Calls the API to create a new Program with the supplied params
   *
   * @param IProgramCreateParams createParams - details of new program
   *
   * @return Observable<APIResponse>
   */
  public createProgram(createParams: IProgramCreateParams): Observable<APIResponse> {
    const url = `${environment.apiURL}/api/programs/create`;
    return this.http.post<APIResponse>(url, createParams);
  }

  /**
   * Returns all the optional demographics
   *
   * @returns Observable<APIResponse>
   */
  public getOptionalDemographics(): Observable<APIResponse> {
    const url = `${environment.apiURL}/api/programs/optional-demographics`;
    return this.http.get<APIResponse>(url);
  }

  /**
   * Calls the API to archive a Program with the specified programId
   *
   * @param number programId
   *
   * @return Observable<APIResponse>
   */
  public archiveProgram(programId: number): Observable<APIResponse> {
    const url = `${environment.apiURL}/api/programs/${programId}`;
    return this.http.delete<APIResponse>(url);
  }

  /**
   * Calls the API to delete a Program with the specified programId
   *
   * @param IDeleteProgramParams deleteParams
   *
   * @return Observable<APIResponse>
   */
  public deleteProgram(deleteParams: IDeleteProgramParams): Observable<APIResponse> {
    const url = `${environment.apiURL}/api/programs/delete`;
    return this.http.post<APIResponse>(url, deleteParams);
  }

  /**
   * Calls the API to edit a Program with new values provided
   *
   * @param IProgramEditParams editParams
   * @param number programId
   *
   * @return Observable<APIResponse>
   */
  public editProgram(editParams: IProgramEditParams, programId: number): Observable<APIResponse> {
    const url = `${environment.apiURL}/api/programs/${programId}/edit`;
    return this.http.put<APIResponse>(url, editParams);
  }

  /**
   * Calls the API to reactivate a program that has been archived
   *
   * @param number programId
   *
   * @return Observable<APIResponse>
   */
  public reactivateProgram(programId: number): Observable<APIResponse> {
    const url = `${environment.apiURL}/api/programs/${programId}/reactivate`;
    return this.http.put<APIResponse>(url, null);
  }

  /**
   * Calls the API to get certain statistics about a specific program
   *
   * @param number programId
   *
   * @return Observable<APIResponse>
   */
  public statistics(programId: number): Observable<APIResponse> {
    const url = `${environment.apiURL}/api/programs/${programId}/statistics`;
    return this.http.get<APIResponse>(url);
  }
}
