import { Injectable } from '@angular/core';
import { APIResponse } from '@shared/definitions';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export default class RegionService {
  constructor(private http: HttpClient) {}

  /**
   * Fetches regions
   *
   * @return Observable<APIResponse>
   */
  public getRegions(): Observable<APIResponse> {
    const getRegionsUrl = `${environment.apiURL}/api/regions/region-list`;
    return this.http.get<APIResponse>(getRegionsUrl);
  }
}
