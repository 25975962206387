<app-alert [loginScreen]="true"></app-alert>
<div class="container vh-100">
  <div class="row">
    <div class="col">
      <div class="card center">
        <img src="/assets/img/Advantage_Logo_medium_RGB.png" alt="logo" />
        <div class="card-body">
          <h2 class="mb-3">Reset Password</h2>
          <div class="row text-center pb-2"></div>
          <form [formGroup]="resetForm">
            <div class="form-group">
              <div class="row">
                <div class="col">
                  <div
                    class="input-group mb-2"
                    [ngClass]="{
                      'error-border':
                        !passwordValid && !resetForm.controls.password.valid
                    }"
                  >
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <i class="fa fa-lock" aria-hidden="true"></i>
                      </div>
                    </div>
                    <input
                      type="password"
                      class="form-control"
                      id="password"
                      formControlName="password"
                      placeholder="Password"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div
                    class="input-group mb-2"
                    [ngClass]="{
                      'error-border':
                        !passwordValid ||
                        (!passwordsMatch &&
                          resetForm.value.password !==
                            resetForm.value.passwordConfirmation)
                    }"
                  >
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <i class="fa fa-lock" aria-hidden="true"></i>
                      </div>
                    </div>
                    <input
                      type="password"
                      class="form-control"
                      id="password-confirm"
                      formControlName="passwordConfirmation"
                      placeholder="Confirm Password"
                      required
                    />
                  </div>
                  <span
                    class="error"
                    *ngIf="
                      (!passwordValid && !resetForm.controls.password.valid) ||
                      (!passwordsMatch &&
                        resetForm.value.password !==
                          resetForm.value.passwordConfirmation)
                    "
                    >{{
                      !passwordValid
                        ? "Please enter a valid password. Passwords must contain a
                    minimum of 8 characters."
                        : "These passwords do not match - please try again."
                    }}</span
                  >
                </div>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col"></div>
              <div class="col">
                <button
                  (click)="onResetClick()"
                  class="btn ag-blue right"
                  [disabled]="loading"
                >
                  <span *ngIf="!loading; else spinner">Reset</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #spinner>
  <div class="spinner" *ngIf="loading">
    <div class="d-flex justify-content-center align-middle">
      <div class="spinner-border" role="status"></div>
    </div>
  </div>
</ng-template>
