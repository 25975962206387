import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { Observable } from 'rxjs';
import { APIResponse } from '@shared/definitions';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export default class AuthService {
  constructor(private http: HttpClient) {}

  /**
   * Retrieves the JWT token from local storage
   *
   * @return string - the JWT token
   */
  public getToken(): string {
    return localStorage.getItem('JWT');
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();

    if (!token) {
      return false;
    }

    const expTimestamp = jwtDecode(token).exp;

    if (!expTimestamp) {
      return false;
    }

    const expDate = new Date(0);
    expDate.setUTCSeconds(expTimestamp);

    return expDate.valueOf() > new Date().valueOf();
  }

  /**
   * Check if the app is in maintenance mode
   *
   * @return Observable<APIResponse>
   */
  public checkMaintenance(): Observable<APIResponse> {
    const url = `${environment.apiURL}/api/maintenance/check`;
    return this.http.get<APIResponse>(url);
  }
}
