import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export default class HttpParamsService {
  /*eslint-disable  @typescript-eslint/no-explicit-any */
  /**
   * Creates a HttpParams object from an array of key-value pairs
   *
   * @param any params
   *
   * @return HttpParams
   */
  public getHttpParams(params: any): HttpParams {
    let httpParams = new HttpParams();

    Object.keys(params).forEach((key: any) => {
      // Don't append param if value is null
      if (params[key] !== null) {
        httpParams = httpParams.append(key, params[key]);
      }
    });

    return httpParams;
  }
}
