import { LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { DatePipe } from '@angular/common';

import { LoginGuard } from './guards/LoginGuard';
import { UserTypeGuard } from './guards/UserTypeGuard';
import TokenInterceptorService from '@services/interceptors/token-interceptor.service';
import MaintenanceInterceptorService from '@services/interceptors/maintenance-interceptor.service';
import { NavigationService } from '@services/navigation.service';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { MaintenanceComponent } from './components/maintenance-page.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { CoverageModule } from './components/coverage/coverage.module';
import { DataManagementModule } from './components/data-management/data-management.module';
import { SharedModule } from '@shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'maintenance-page', component: MaintenanceComponent },
  {
    path: 'coverage',
    loadChildren: () =>
      import('./components/coverage/coverage.module').then((m) => m.CoverageModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'survey-response-tracker',
    loadChildren: () =>
      import('./components/survey-response-tracker/survey-response-tracker.module').then(
        (m) => m.SurveyResponseTrackerModule,
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./components/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [LoginGuard],
  },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  declarations: [AppComponent, LoginComponent, HeaderComponent, ResetPasswordComponent],
  bootstrap: [AppComponent],
  imports: [
    SharedModule,
    RouterModule.forRoot(appRoutes),
    CoverageModule,
    DataManagementModule,
    BrowserModule,
  ],
  providers: [
    LoginGuard,
    UserTypeGuard,
    DatePipe,
    NavigationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceInterceptorService,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
  ],
})
export class AppModule {}
