import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-program-and-product',
  templateUrl: './program-and-product.component.html',
  styleUrls: ['./program-and-product.component.scss'],
})
export default class ProgramAndProductComponent implements OnInit {
  public constructor() {}

  public ngOnInit(): void {}
}
