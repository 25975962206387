<div class="background"></div>
<div class="page">
  <img
    class="img-size"
    src="assets/img/Advantage_Logo_medium_RGB.png"
    alt="logo"
  />
  <h1 class="header text-styling">
    This website is currently down for scheduled maintenance - we'll be back
    soon
  </h1>
</div>
