import { Component, OnInit } from '@angular/core';
import UserService from '@services/user/user.service';
import { APIResponse } from '@shared/definitions';
import AuthService from '@services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private userService: UserService,
    public authService: AuthService,
  ) {}

  ngOnInit(): void {
    const token = this.authService.getToken();
    if (token) {
      this.userService.getCurrent().subscribe((response: APIResponse) => {
        this.userService.setCurrentUserType(response.data.userTypeTag);
        this.userService.setCurrentUser(response.data.user);
      });
    }
  }
}
