import { Component, OnInit } from '@angular/core';
import UserService from '@services/user/user.service';
import { APIResponse, CheckBoxItem, User } from '@shared/definitions';
import LoginService from '@services/user/login.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import AlertService from '@services/alert.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import RegionService from '@services/region.service';
import ProgramService from '@services/program/program.service';
import { IRegionData, IRegionResponse } from '@interfaces/region';
import { IEditUserParams } from '@interfaces/user/user';
import { NavigationService, INavItems } from '@services/navigation.service';
import AuthService from '@services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public currentUser: User;
  public editUserForm: UntypedFormGroup;
  public editUserPasswordForm: UntypedFormGroup;
  public showEditUserModal = false;
  public editFormValid = true;
  public editEmailValid = true;
  public editPasswordFormValid = true;
  public loading = false;
  public passwordFormMessage: string = null;

  public navItems: INavItems[] = this.navigationService.getNavItems;

  // Checkboxes
  public regionsSelected: string[] = [];
  public regionCheckBoxes: CheckBoxItem[] = [];
  public regionValid = true;

  constructor(
    private userService: UserService,
    private loginService: LoginService,
    private router: Router,
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    private regionService: RegionService,
    private programService: ProgramService,
    private navigationService: NavigationService,
    public authService: AuthService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.userService.currentUser.subscribe((user: User) => {
      this.currentUser = user;
    });
    // Init forms
    this.initEditUserForm();
    this.initEditUserPasswordForm();
    await this.getRegions();
  }

  /**
   * @param event
   * @param route
   */
  public navigate(event, item: INavItems): void {
    event.stopPropagation();

    if (item.logout) {
      this.logout();
      return;
    }

    if (item.userSettings) {
      this.showEditUserModal = true;
      return;
    }

    this.router.navigate([item.route]);
  }

  /**
   * Gets the regions
   *
   * @return void
   */
  public async getRegions(): Promise<void> {
    this.loading = true;

    await this.regionService.getRegions().subscribe({
      next: (response: APIResponse) => {
        this.regionCheckBoxes = response.data.regions.map((region: IRegionData) => {
          return {
            name: region.regionName,
            checked: false,
            value: region.regionTag,
            disabled: false,
          };
        });
        this.loading = false;
      },
      error: () => {
        this.alertService.emitErrorAlert('Failed to get region data.');
        this.loading = false;
      },
    });
  }

  /**
   * Logs the current user out
   *
   * @return void
   */
  public logout(): void {
    this.loginService.logout().subscribe({
      next: () => {
        localStorage.removeItem('JWT');
        this.router.navigate(['/login']);
        this.alertService.emitSuccessAlert('Logout Successful');
      },
      error: () => {
        this.alertService.emitErrorAlert('Logout Failed');
      },
    });
  }

  public onUserSettingsClick(): void {
    this.patchEditUserForm(this.currentUser);
    this.editUserPasswordForm.reset();
    this.showEditUserModal = true;
  }

  /**
   * Starts the process of editing an existing user
   *
   * @param number userId
   *
   * @return void
   */
  public editUser(): void {
    this.editFormValid = this.editUserForm.valid;
    this.editEmailValid = this.editUserForm.controls.email.valid;
    this.regionValid = this.regionsSelected.length > 0;

    if (this.editFormValid && this.checkPasswordFormValid()) {
      this.loading = true;

      const regionsSelected = this.regionsSelected.join(',');

      const params: IEditUserParams = {
        firstName: this.editUserForm.controls.firstName.value,
        lastName: this.editUserForm.controls.lastName.value,
        email: this.editUserForm.controls.email.value,
        regions: regionsSelected,
        oldPassword: this.editUserPasswordForm.controls.oldPassword.value,
        newPassword: this.editUserPasswordForm.controls.password.value,
        newPasswordConfirm: this.editUserPasswordForm.controls.passwordConfirm.value,
      };

      this.userService.editUser(params, this.currentUser.userId).subscribe({
        next: (response: APIResponse) => {
          this.alertService.emitSuccessAlert('Changes saved');
          this.userService.setCurrentUser(response.data.user);
          this.currentUser = response.data.user;
          this.onCloseEditUserModal();
          this.programService.setRefreshProgramList(true);
          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.alertService.emitErrorAlert(error.error.message || 'Failed to save changes');
          this.loading = false;
        },
      });
    }
  }

  /**
   * Closes the edit user modal
   *
   * @return void
   */
  public onCloseEditUserModal() {
    this.showEditUserModal = false;
    this.regionsSelected = [];
    this.regionCheckBoxes.map((regionCheckBoxItem: CheckBoxItem) => {
      regionCheckBoxItem.checked = false;
    });
  }

  /**
   * Initialses the edit user form, and related listeners
   *
   * @return void
   */
  private initEditUserForm(): void {
    this.editUserForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });

    this.editUserForm.valueChanges.subscribe(() => {
      this.editFormValid = true;
    });

    this.editUserForm.controls.email.valueChanges.subscribe(() => {
      this.editEmailValid = true;
    });
  }

  /**
   * Initialses the edit user form, and related listeners
   *
   * @return void
   */
  private initEditUserPasswordForm(): void {
    this.editUserPasswordForm = this.fb.group({
      oldPassword: ['', Validators.required],
      password: ['', [Validators.minLength(7), Validators.maxLength(15)]],
      passwordConfirm: ['', Validators.required],
    });

    this.editUserPasswordForm.valueChanges.subscribe(() => {
      this.passwordFormMessage = null;
    });
  }

  /**
   * Patches the edit user form before editing a user
   *
   * @param User user
   *
   * @return void
   */
  private patchEditUserForm(user: User): void {
    this.editUserForm.patchValue({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    });

    user.user_regions.map((region: IRegionResponse) => {
      this.regionsSelected.push(region.region.regionTag);
      this.regionCheckBoxes.map((regionCheckBoxItem: CheckBoxItem) => {
        return regionCheckBoxItem.value === region.region.regionTag
          ? (regionCheckBoxItem.checked = true)
          : '';
      });
    });
  }

  /**
   * Checks the validity of the edit user password form
   *
   * @return boolean
   */
  private checkPasswordFormValid(): boolean {
    const controls = this.editUserPasswordForm.controls;

    const hasValue =
      !!controls.oldPassword.value || !!controls.password.value || !!controls.passwordConfirm.value;

    const allHaveValues =
      !!controls.oldPassword.value && !!controls.password.value && !!controls.passwordConfirm.value;

    const passwordsMatch = controls.password.value === controls.passwordConfirm.value;

    if (hasValue && !allHaveValues) {
      this.passwordFormMessage = 'All fields are required';
    } else if (!controls.password.valid) {
      this.passwordFormMessage =
        'This password does not meet the minimum requirements. Passwords must have a minimum of 7 characters and a maximum of 15 characters';
    } else if (!passwordsMatch) {
      this.passwordFormMessage = 'Passwords must match';
    }

    return hasValue ? this.editUserPasswordForm.valid && passwordsMatch : true;
  }

  public get newPasswordControl(): AbstractControl {
    return this.editUserPasswordForm.controls.password;
  }

  public get passwordConfirmControl(): AbstractControl {
    return this.editUserPasswordForm.controls.passwordConfirm;
  }
}
