import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import UserService from '@services/user/user.service';

@Injectable()
export class UserTypeGuard {
  public redirects: { INTERVIEWER: string; TRANSCRIBER: string };
  public currentProgramId: string = null;

  constructor(
    private userService: UserService,
    private router: Router,
  ) {}

  /**
   * Checks the user can activate this route
   *
   * @param next - next guard (if any)
   * @param state - data passed into route (if any)
   *
   * @return boolean
   */
  public canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    // Get array of permitted userTypeIds
    this.currentProgramId = route.params.programId;
    this.redirects = route.data.redirects;
    const permittedUserTypes: string[] = route.data.permittedUserTypes;

    return this.userHasType(permittedUserTypes);
  }

  /**
   * Check if the user has a permitted user type
   *
   * @param string[] permittedTypes
   *
   * @return Promise<boolean>
   */
  public userHasType(permittedTypes: string[]): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.userService.currentUserType.subscribe((userType: string) => {
        if (userType) {
          if (permittedTypes.includes(userType)) {
            resolve(true);
          } else {
            this.router.navigateByUrl(this.getRedirectUrl(userType));
            resolve(false);
          }
        }
      });
    });
  }

  /**
   * Gets the redirect url if the user cannot access this route
   *
   * @param string currentUserType
   *
   * @return string
   */
  private getRedirectUrl(currentUserType: string): string {
    if (this.redirects && currentUserType && this.redirects[currentUserType]) {
      return `/dashboard/programs/${this.currentProgramId}/${this.redirects[currentUserType]}`;
    }
    return '/dashboard';
  }
}
