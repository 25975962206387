<app-alert [loginScreen]="true"></app-alert>
<div class="container vh-100">
  <div class="row">
    <div class="col">
      <div class="card center">
        <img src="/assets/img/Advantage_Logo_medium_RGB.png" alt="logo" />
        <div class="card-body">
          <h2 class="mb-3">Login</h2>
          <div class="row text-center pb-2">
          </div>
          <form [formGroup]="loginForm">
            <div class="form-group mx-auto">
              <div class="row">
                <div class="col">
                  <div
                    class="input-group mb-2"
                    [ngClass]="{
                      'error-border': !emailValid
                    }"
                  >
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <i class="fa fa-user" aria-hidden="true"></i>
                      </div>
                    </div>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      formControlName="email"
                      placeholder="Email"
                      (keyup)="emailValid = true"
                      required
                    />
                  </div>
                  <span class="error" *ngIf="!emailValid"
                    >Please enter a valid email address.</span
                  >
                </div>
              </div>
            </div>

            <div class="form-group" *ngIf="!forgotPasswordClicked">
              <div class="row">
                <div class="col">
                  <div
                    class="input-group mb-2"
                    [ngClass]="{
                      'error-border': !passwordValid
                    }"
                  >
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <i class="fa fa-lock" aria-hidden="true"></i>
                      </div>
                    </div>
                    <input
                      type="password"
                      class="form-control"
                      id="password"
                      formControlName="password"
                      placeholder="Password"
                      (keyup)="passwordValid = true"
                      required
                    />
                  </div>
                  <span class="error" *ngIf="!passwordValid"
                    >Please enter a password.</span
                  >
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <p
                  class="link lh-2-5"
                  (click)="
                    loading
                      ? null
                      : (forgotPasswordClicked = !forgotPasswordClicked)
                  "
                >
                  {{
                    forgotPasswordClicked ? "Go Back" : "Forgot your password?"
                  }}
                </p>
              </div>
              <div class="col-6">
                <button
                  (click)="
                    forgotPasswordClicked ? forgotPassword() : onLoginClick()
                  "
                  class="btn ag-blue right"
                  [disabled]="loading"
                >
                  <span *ngIf="!loading; else spinner">{{
                    forgotPasswordClicked ? "Send Reset Email" : "Login"
                  }}</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #spinner>
  <div class="spinner" *ngIf="loading">
    <div class="d-flex justify-content-center align-middle">
      <div class="spinner-border" role="status"></div>
    </div>
  </div>
</ng-template>
