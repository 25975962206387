import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
// Interfaces
import { APIResponse } from '@shared/definitions';
import { ILoginDetails } from '@interfaces/user/login';

@Injectable({
  providedIn: 'root',
})
export default class LoginService {
  constructor(private http: HttpClient) {}

  /**
   * Sends login details to the backend for authentication
   *
   * @param loginDetails loginDetails - email & password
   *
   * @return Observable<APIResponse> - response from the backend
   */
  public login(loginDetails: ILoginDetails): Observable<APIResponse> {
    const url = `${environment.apiURL}/auth/login`;
    return this.http.post<APIResponse>(url, { loginDetails });
  }

  /**\
   * Sends a request to the API to log the user out
   *
   * @return Observable<APIResponse>
   */
  public logout(): Observable<APIResponse> {
    const url = `${environment.apiURL}/auth/logout`;
    return this.http.get<APIResponse>(url);
  }
}
