import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
// Services
import AlertService from '@services/alert.service';
import ForgottenPasswordService from '@services/user/forgotten-password.service';
import { IForgottenPasswordParams } from '@interfaces/user/forgotten-password';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  public resetForm: UntypedFormGroup;
  public loading = false;
  public passwordValid = true;
  public passwordsMatch = true;
  private token: string;

  constructor(
    private forgottenPasswordService: ForgottenPasswordService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.resetForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      passwordConfirmation: [''],
    });

    this.token = this.route.snapshot.paramMap.get('token');
  }

  /**
   * Checks the validity of the reset form
   *
   * @return void
   */
  public onResetClick(): void {
    this.passwordValid = this.resetForm.controls.password.valid;
    this.passwordsMatch =
      this.resetForm.value.password === this.resetForm.value.passwordConfirmation;

    if (this.passwordValid && this.passwordsMatch) {
      this.resetPassword();
    }
  }

  /**
   * Starts the process of resetting the user's password
   *
   * @return void
   */
  private resetPassword(): void {
    this.loading = true;

    const params: IForgottenPasswordParams = {
      password: this.resetForm.value['password'],
      token: this.token,
    };

    this.forgottenPasswordService.resetPassword(params).subscribe({
      next: () => {
        this.alertService.emitSuccessAlert('Password reset');
        this.router.navigate(['/login']);
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.alertService.emitErrorAlert(error.error.message || 'Reset failed');
        this.router.navigate(['/login']);
        this.loading = false;
      },
    });
  }
}
