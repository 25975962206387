<div class="multiselect" (clickOutside)="display = false">
  <div class="selectBox" (click)="display = !display">
    <select class="form-control" [disabled]="disabled">
      <option>{{ selectedString }}</option>
    </select>
    <div class="overSelect"></div>
  </div>
  <div class="invisible-scroll">
    <div
      class="checkboxes"
      [ngStyle]="{
        display: display ? 'block' : 'none'
      }"
    >
      <input
        *ngIf="filterable"
        type="text"
        class="form-control mb-2"
        placeholder="Type to filter..."
        [(ngModel)]="filterText"
        (input)="filterItems()"
      />
      <div class="form-check">
        <label
          class="form-check-label ml-2 p-1 pointer mt-2 mb-2"
          *ngIf="allowAll && !isFiltered()"
        >
          <input
            class="form-check-input"
            type="checkbox"
            value="0"
            (click)="onCheckBoxClick(null)"
            [checked]="valuesSelected.length === items.length"
            [disabled]="disabled"
          />
          <strong>All</strong>
        </label>
        <label
          class="form-check-label ml-2 p-1 pointer mt-2 mb-2"
          *ngFor="let item of filteredItems; let i = index"
        >
          <input
            class="form-check-input"
            type="checkbox"
            [value]="i + 1"
            (click)="onCheckBoxClick(item)"
            [checked]="item.checked"
            [disabled]="disabled || item.disabled"
          />
          {{ item.name }}
        </label>
      </div>
    </div>
  </div>
</div>
