import { NgModule } from '@angular/core';
import { AlertComponent } from './components/alert/alert.component';
import { BannerComponent } from './components/banner/banner.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgClickOutsideDirective, NgClickOutsideExcludeDirective } from 'ng-click-outside2';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MultiSelectCheckboxesComponent } from './components/multi-select-checkboxes/multi-select-checkboxes.component';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import {MatCardModule} from "@angular/material/card";
import {MatButtonModule} from "@angular/material/button";

@NgModule({
  declarations: [
    AlertComponent,
    BannerComponent,
    MultiSelectCheckboxesComponent,
    GenericModalComponent,
  ],
  imports: [
    CommonModule,
    NgClickOutsideExcludeDirective,
    NgClickOutsideDirective,
    ReactiveFormsModule,
    FormsModule,
    DragDropModule,
    MatIconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatButtonModule
  ],
  exports: [
    AlertComponent,
    BannerComponent,
    MultiSelectCheckboxesComponent,
    GenericModalComponent,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    NgClickOutsideDirective,
    NgClickOutsideExcludeDirective,
    MatAutocompleteModule,
    DragDropModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule
  ],
})
export class SharedModule {}
